import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useCartContext } from '../context/CartContext';
import styles from '../styles/electronic-store.module.css';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import Badge from '@mui/material/Badge';

export const Header: React.FC = () => {
  const { cart } = useCartContext();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const location = useLocation();

  const totalItems = cart.reduce((sum, item) => sum + item.quantity, 0);

  // Закрываем меню при изменении маршрута
  useEffect(() => {
    setIsMenuOpen(false);
  }, [location]);

  // Блокируем прокрутку при открытом меню
  useEffect(() => {
    if (isMenuOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [isMenuOpen]);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  const isActive = (path: string) => {
    return location.pathname === path ? styles.active : '';
  };

  return (
    <header className={styles.header}>
      <div className={styles.headerContent}>
        <button 
          className={styles.menuButton} 
          onClick={toggleMenu}
          aria-label={isMenuOpen ? 'Закрыть меню' : 'Открыть меню'}
        >
          {isMenuOpen ? <CloseIcon /> : <MenuIcon />}
        </button>

        <Link to="/" className={styles.logo} onClick={closeMenu}>
          PixelTrek
        </Link>

        <nav className={`${styles.navigation} ${isMenuOpen ? styles.navigationOpen : ''}`}>
          <Link 
            to="/" 
            className={`${styles.navLink} ${isActive('/')}`}
            onClick={closeMenu}
          >
            Главная
          </Link>
          <Link 
            to="/catalog" 
            className={`${styles.navLink} ${isActive('/catalog')}`}
            onClick={closeMenu}
          >
            Каталог
          </Link>
          <Link 
            to="/contacts" 
            className={`${styles.navLink} ${isActive('/contacts')}`}
            onClick={closeMenu}
          >
            Контакты
          </Link>
          <Link 
            to="/delivery" 
            className={`${styles.navLink} ${isActive('/delivery')}`}
            onClick={closeMenu}
          >
            Доставка и оплата
          </Link>
          <Link 
            to="/faq" 
            className={`${styles.navLink} ${isActive('/faq')}`}
            onClick={closeMenu}
          >
            FAQ
          </Link>
        </nav>

        <Link to="/cart" className={styles.cartButton} onClick={closeMenu}>
          <Badge badgeContent={totalItems} color="error">
            <ShoppingCartIcon />
          </Badge>
        </Link>
      </div>
    </header>
  );
};