import React, { useState } from 'react';
import styles from '../styles/electronic-store.module.css';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import TelegramIcon from '@mui/icons-material/Telegram';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

export const ContactsPage: React.FC = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    topic: 'general',
    message: ''
  });

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    // Здесь будет логика отправки формы
    console.log('Form submitted:', formData);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  return (
    <div className={styles.contactsContainer}>
      <div className={styles.contactsHeader}>
        <h1>Свяжитесь с нами</h1>
        <p>Мы всегда готовы помочь вам и ответить на все вопросы</p>
      </div>

      <div className={styles.contactsContent}>
        <div className={styles.contactsInfo}>
          <h2>Контактная информация</h2>
          
          <div className={styles.contactCard}>
            <PhoneIcon />
            <div>
              <h3>Телефон</h3>
              <p>(+855) 87 550 787</p>
              <p>(+855) 11 323 942</p>
            </div>
          </div>

          <div className={styles.contactCard}>
            <EmailIcon />
            <div>
              <h3>Email</h3>
              <p>pixeltrek.ltd@gmail.com</p>
            </div>
          </div>

          <div className={styles.contactCard}>
            <LocationOnIcon />
            <div>
              <h3>Адрес</h3>
              <p>г. Москва, ул. Цифровая 10</p>
              <p>Бизнес-центр "Digital", офис 505</p>
            </div>
          </div>

          <div className={styles.contactCard}>
            <AccessTimeIcon />
            <div>
              <h3>Режим работы</h3>
              <p>Пн-Пт: 9:00 - 20:00</p>
              <p>Сб-Вс: 10:00 - 18:00</p>
            </div>
          </div>

          <div className={styles.socialLinks}>
            <a href="#" className={styles.socialButton}>
              <TelegramIcon /> Telegram
            </a>
            <a href="#" className={styles.socialButton}>
              <WhatsAppIcon /> WhatsApp
            </a>
          </div>
        </div>

        <div className={styles.contactForm}>
          <h2>Форма обратной связи</h2>
          <form onSubmit={handleSubmit}>
            <div className={styles.formGroup}>
              <label htmlFor="name">Ваше имя</label>
              <input
                type="text"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                required
              />
            </div>

            <div className={styles.formGroup}>
              <label htmlFor="email">Email</label>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
              />
            </div>

            <div className={styles.formGroup}>
              <label htmlFor="topic">Тема обращения</label>
              <select
                id="topic"
                name="topic"
                value={formData.topic}
                onChange={handleChange}
              >
                <option value="general">Общий вопрос</option>
                <option value="support">Техническая поддержка</option>
                <option value="billing">Вопросы оплаты</option>
                <option value="partnership">Сотрудничество</option>
              </select>
            </div>

            <div className={styles.formGroup}>
              <label htmlFor="message">Сообщение</label>
              <textarea
                id="message"
                name="message"
                value={formData.message}
                onChange={handleChange}
                required
                rows={5}
              />
            </div>

            <button type="submit" className={styles.submitButton}>
              Отправить сообщение
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};