import React from 'react';
import styles from '../styles/electronic-store.module.css';
import SecurityIcon from '@mui/icons-material/Security';
import SpeedIcon from '@mui/icons-material/Speed';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';

export const AboutPage: React.FC = () => {
  return (
    <div className={styles.aboutContainer}>
      <div className={styles.aboutHero}>
        <h1>О компании Digital Store</h1>
        <p className={styles.aboutSubtitle}>
          Ваш надежный партнер в мире цифровых товаров с 2020 года
        </p>
      </div>

      <div className={styles.aboutContent}>
        <section className={styles.aboutSection}>
          <h2>Наша история</h2>
          <p>
            Digital Store начал свой путь в 2020 году как небольшой магазин цифровых товаров.
            За это время мы выросли в крупную платформу, которой доверяют тысячи клиентов по всей России.
            Мы постоянно развиваемся и расширяем ассортимент, чтобы предложить вам самые современные и качественные цифровые продукты.
          </p>
        </section>

        <section className={styles.advantagesSection}>
          <h2>Наши преимущества</h2>
          <div className={styles.advantagesGrid}>
            <div className={styles.advantageCard}>
              <SecurityIcon className={styles.advantageIcon} />
              <h3>Безопасность</h3>
              <p>Гарантируем безопасность всех платежей и сохранность ваших данных</p>
            </div>
            <div className={styles.advantageCard}>
              <SpeedIcon className={styles.advantageIcon} />
              <h3>Скорость</h3>
              <p>Мгновенная доставка цифровых товаров после оплаты</p>
            </div>
            <div className={styles.advantageCard}>
              <SupportAgentIcon className={styles.advantageIcon} />
              <h3>Поддержка 24/7</h3>
              <p>Наши специалисты готовы помочь в любое время дня и ночи</p>
            </div>
            <div className={styles.advantageCard}>
              <LocalShippingIcon className={styles.advantageIcon} />
              <h3>Гарантия</h3>
              <p>Предоставляем гарантию на все цифровые товары</p>
            </div>
          </div>
        </section>

        <section className={styles.statsSection}>
          <h2>Digital Store в цифрах</h2>
          <div className={styles.statsGrid}>
            <div className={styles.statCard}>
              <h3>50,000+</h3>
              <p>Довольных клиентов</p>
            </div>
            <div className={styles.statCard}>
              <h3>10,000+</h3>
              <p>Цифровых товаров</p>
            </div>
            <div className={styles.statCard}>
              <h3>99.9%</h3>
              <p>Успешных заказов</p>
            </div>
            <div className={styles.statCard}>
              <h3>24/7</h3>
              <p>Поддержка клиентов</p>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

