// src/pages/FaqPage.tsx
import React, { useState } from 'react';
import styles from '../styles/electronic-store.module.css';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionSummary, AccordionDetails, Typography } from '@mui/material';

interface FaqItem {
  question: string;
  answer: string;
  category: string;
}

export const FaqPage: React.FC = () => {
  const [expanded, setExpanded] = useState<string | false>(false);

  const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };

  const faqItems: FaqItem[] = [
    {
      category: "Оплата",
      question: "Какие способы оплаты вы принимаете?",
      answer: "Мы принимаем оплату банковскими картами (Visa, MasterCard, МИР), электронными кошельками (ЮMoney, QIWI), а также банковским переводом для юридических лиц."
    },
    {
      category: "Оплата",
      question: "Безопасно ли оплачивать на вашем сайте?",
      answer: "Да, все платежи на нашем сайте защищены. Мы используем современные протоколы шифрования и не храним данные банковских карт."
    },
    {
      category: "Доставка",
      question: "Как быстро я получу доступ к купленному товару?",
      answer: "После успешной оплаты вы моментально получите доступ к купленному цифровому товару. Ссылка на скачивание будет отправлена на вашу электронную почту."
    },
    {
      category: "Гарантии",
      question: "Что делать, если товар не работает?",
      answer: "В случае возникновения проблем с товаром, обратитесь в нашу службу поддержки. Мы поможем решить проблему или вернем деньги в течение 14 дней."
    },
    {
      category: "Гарантии",
      question: "Предоставляете ли вы гарантию на товары?",
      answer: "Да, мы предоставляем гарантию на все цифровые товары. Срок гарантии зависит от типа товара и указан в его описании."
    }
  ];

  const categories = Array.from(new Set(faqItems.map(item => item.category)));

  return (
    <div className={styles.faqContainer}>
      <div className={styles.faqHeader}>
        <h1>Часто задаваемые вопросы</h1>
        <p>Найдите ответы на самые популярные вопросы о нашем магазине</p>
      </div>

      <div className={styles.faqContent}>
        {categories.map(category => (
          <div key={category} className={styles.faqCategory}>
            <h2>{category}</h2>
            {faqItems
              .filter(item => item.category === category)
              .map((item, index) => (
                <Accordion
                  key={index}
                  expanded={expanded === `panel${category}${index}`}
                  onChange={handleChange(`panel${category}${index}`)}
                  className={styles.faqAccordion}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls={`panel${index}d-content`}
                    id={`panel${index}d-header`}
                  >
                    <Typography className={styles.faqQuestion}>
                      {item.question}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography className={styles.faqAnswer}>
                      {item.answer}
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              ))}
          </div>
        ))}
      </div>
    </div>
  );
};