import React from 'react';
import { Link } from 'react-router-dom';
import styles from '../styles/electronic-store.module.css';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import TelegramIcon from '@mui/icons-material/Telegram';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import InstagramIcon from '@mui/icons-material/Instagram';

export const Footer: React.FC = () => {
  return (
    <footer className={styles.footer}>
      <div className={styles.footerContent}>
        <div className={styles.footerSection}>
          <h4>О компании</h4>
          <ul>
            <li><Link to="/about">О нас</Link></li>
            <li><Link to="/contacts">Контакты</Link></li>
            <li><Link to="/career">Вакансии</Link></li>
            <li><Link to="/news">Новости</Link></li>
          </ul>
        </div>

        <div className={styles.footerSection}>
          <h4>Покупателям</h4>
          <ul>
            <li><Link to="/delivery">Доставка</Link></li>
            <li><Link to="/payment">Оплата</Link></li>
            <li><Link to="/return">Возврат</Link></li>
            <li><Link to="/faq">FAQ</Link></li>
          </ul>
        </div>

        <div className={styles.footerSection}>
          <h4>Контакты</h4>
          <div className={styles.contactList}>
            <div className={styles.contactItem}>
              <PhoneIcon />
              <span>(+855) 87 550 787</span>
            </div>
            <div className={styles.contactItem}>
              <EmailIcon />
              <span>pixeltrek.ltd@gmail.com</span>
            </div>
            {/* <div className={styles.contactItem}>
              <LocationOnIcon />
              <span>г. Москва, ул. Цифровая 10</span>
            </div> */}
          </div>
        </div>

        <div className={styles.footerSection}>
          <h4>Мы в соцсетях</h4>
          <div className={styles.socialLinks}>
            <a href="#" className={styles.socialIcon}><TelegramIcon /></a>
            <a href="#" className={styles.socialIcon}><WhatsAppIcon /></a>
            <a href="#" className={styles.socialIcon}><InstagramIcon /></a>
          </div>
        </div>
      </div>
      
      <div className={styles.footerBottom}>
        <p>&copy; 2024 Pixeltrek Tech Co. Все права защищены.</p>
        <div className={styles.footerLinks}>
          <Link to="/privacy">Политика конфиденциальности</Link>
          <Link to="/terms">Пользовательское соглашение</Link>
        </div>
      </div>
    </footer>
  );
};

