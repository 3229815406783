import React, { useState } from 'react';
import { useProductContext } from '../context/ProductContext';
import { useCartContext } from '../context/CartContext';
import styles from '../styles/electronic-store.module.css';
import FilterListIcon from '@mui/icons-material/FilterList';
import CloseIcon from '@mui/icons-material/Close';
import SortIcon from '@mui/icons-material/Sort';
import SearchIcon from '@mui/icons-material/Search';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { ProductList } from '../components/ProductList';

interface FilterState {
 category: string;
 priceRange: string;
 sort: string;
 search: string;
 limit: number
}

export const CatalogPage: React.FC = () => {
 const { products } = useProductContext();
 const { addToCart } = useCartContext();
 const [isFilterOpen, setIsFilterOpen] = useState(false);
 const [filters, setFilters] = useState<FilterState>({
   category: 'all',
   priceRange: 'all',
   sort: 'default',
   search: '',
   limit: 0
 });

 const categories = [
    { 
      id: 'ready-solutions', 
      name: 'Готовые решения',
      description: 'CRM, CMS, ERP системы и другие готовые решения'
    },
    { 
      id: 'development', 
      name: 'Разработка и интеграция',
      description: 'Разработка ПО и интеграция с существующими системами'
    },
    { 
      id: 'automation', 
      name: 'Автоматизация',
      description: 'Решения для автоматизации бизнес-процессов'
    },
    { 
      id: 'data-systems', 
      name: 'Системы хранения/управления данными',
      description: 'Базы данных, хранилища, системы управления данными'
    }
  ];

 const priceRanges = [
   { id: 'all', name: 'Все цены' },
   { id: 'under-1000', name: 'До 1000 ₽' },
   { id: '1000-5000', name: '1000 ₽ - 5000 ₽' },
   { id: 'over-5000', name: 'Более 5000 ₽' }
 ];

 const sortOptions = [
   { id: 'default', name: 'По умолчанию' },
   { id: 'price-asc', name: 'Цена: по возрастанию' },
   { id: 'price-desc', name: 'Цена: по убыванию' },
   { id: 'name-asc', name: 'По названию: А-Я' }
 ];

 const handleFilterChange = (name: keyof FilterState, value: string) => {
   setFilters(prev => ({ ...prev, [name]: value }));
 };

 const toggleFilter = () => {
   setIsFilterOpen(!isFilterOpen);
 };

 const filteredProducts = products
   .filter(product => {
     if (filters.category !== 'all' && product.category !== filters.category) return false;
     if (filters.search && !product.name.toLowerCase().includes(filters.search.toLowerCase())) return false;
     
     if (filters.priceRange !== 'all') {
       const price = product.price;
       switch (filters.priceRange) {
         case 'under-1000':
           if (price >= 1000) return false;
           break;
         case '1000-5000':
           if (price < 1000 || price > 5000) return false;
           break;
         case 'over-5000':
           if (price <= 5000) return false;
           break;
       }
     }
     return true;
   })
   .sort((a, b) => {
     switch (filters.sort) {
       case 'price-asc':
         return a.price - b.price;
       case 'price-desc':
         return b.price - a.price;
       case 'name-asc':
         return a.name.localeCompare(b.name);
       default:
         return 0;
     }
   });

 return (
   <div className={styles.catalogContainer}>
     {/* Header с поиском и кнопкой фильтра */}
     <div className={styles.catalogHeader}>
       <div className={styles.searchBox}>
         <SearchIcon />
         <input
           type="text"
           placeholder="Поиск товаров..."
           value={filters.search}
           onChange={(e) => handleFilterChange('search', e.target.value)}
         />
       </div>
       <button className={styles.filterButton} onClick={toggleFilter}>
         <FilterListIcon /> Фильтры
       </button>
     </div>

     {/* Выдвижная панель фильтров */}
     <div className={`${styles.filterDrawer} ${isFilterOpen ? styles.filterDrawerOpen : ''}`}>
       <div className={styles.filterDrawerHeader}>
         <h2>Фильтры</h2>
         <button className={styles.closeFilterButton} onClick={toggleFilter}>
           <CloseIcon />
         </button>
       </div>

       <div className={styles.filterContent}>
        <div className={styles.filterSection}>
            <h3>Категории</h3>
            <div className={styles.filterOptions}>
                <label className={styles.categoryOption}>
                <input
                    type="radio"
                    name="category"
                    value="all"
                    checked={filters.category === 'all'}
                    onChange={(e) => handleFilterChange('category', e.target.value)}
                />
                <div className={styles.categoryContent}>
                    <span className={styles.categoryName}>Все категории</span>
                </div>
                </label>
                {categories.map(category => (
                <label key={category.id} className={styles.categoryOption}>
                    <input
                    type="radio"
                    name="category"
                    value={category.id}
                    checked={filters.category === category.id}
                    onChange={(e) => handleFilterChange('category', e.target.value)}
                    />
                    <div className={styles.categoryContent}>
                    <span className={styles.categoryName}>{category.name}</span>
                    <span className={styles.categoryDescription}>{category.description}</span>
                    </div>
                </label>
                ))}
            </div>
            </div>

         <div className={styles.filterSection}>
           <h3>Ценовой диапазон</h3>
           <div className={styles.filterOptions}>
             {priceRanges.map(range => (
               <label key={range.id}>
                 <input
                   type="radio"
                   name="priceRange"
                   value={range.id}
                   checked={filters.priceRange === range.id}
                   onChange={(e) => handleFilterChange('priceRange', e.target.value)}
                 />
                 {range.name}
               </label>
             ))}
           </div>
         </div>
       </div>

       <div className={styles.filterActions}>
         <button 
           className={styles.applyFiltersButton}
           onClick={toggleFilter}
         >
           Применить фильтры
         </button>
         <button 
           className={styles.resetFiltersButton}
           onClick={() => {
             setFilters({
               category: 'all',
               priceRange: 'all',
               sort: 'default',
               search: '',
               limit: 0
             });
           }}
         >
           Сбросить
         </button>
       </div>
     </div>

     {/* Затемнение фона при открытом фильтре */}
     {isFilterOpen && (
       <div className={styles.overlay} onClick={toggleFilter} />
     )}

     {/* Основной контент */}
     <main className={styles.catalogProducts}>
       <div className={styles.productsHeader}>
         <div className={styles.activeFilters}>
           {filters.category !== 'all' && (
             <span className={styles.filterTag}>
               {categories.find(c => c.id === filters.category)?.name}
               <button onClick={() => handleFilterChange('category', 'all')}>
                 <CloseIcon />
               </button>
             </span>
           )}
           {filters.priceRange !== 'all' && (
             <span className={styles.filterTag}>
               {priceRanges.find(p => p.id === filters.priceRange)?.name}
               <button onClick={() => handleFilterChange('priceRange', 'all')}>
                 <CloseIcon />
               </button>
             </span>
           )}
         </div>
         <div className={styles.sortContainer}>
           <SortIcon />
           <select
             value={filters.sort}
             onChange={(e) => handleFilterChange('sort', e.target.value)}
             className={styles.sortSelect}
           >
             {sortOptions.map(option => (
               <option key={option.id} value={option.id}>
                 {option.name}
               </option>
             ))}
           </select>
         </div>
       </div>
       
       <ProductList filters={filters}/>
     </main>
   </div>
 );
};