import React from 'react';
import { useProductContext } from '../context/ProductContext';
import { useCartContext } from '../context/CartContext';
import styles from '../styles/electronic-store.module.css';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import StarIcon from '@mui/icons-material/Star';
import { List } from '@mui/material';


export const ProductList: React.FC<{filters: any}> = ({ filters }: {filters: any}) => {
  const { products } = useProductContext();
  const { addToCart } = useCartContext();
  const filteredProducts = products
   .filter(product => {
     if (filters.category !== 'all' && product.category !== filters.category) return false;
     if (filters.search && !product.name.toLowerCase().includes(filters.search.toLowerCase())) return false;
     
     if (filters.priceRange !== 'all') {
       const price = product.price;
       switch (filters.priceRange) {
         case 'under-1000':
           if (price >= 1000) return false;
           break;
         case '1000-5000':
           if (price < 1000 || price > 5000) return false;
           break;
         case 'over-5000':
           if (price <= 5000) return false;
           break;
       }
     }
     return true;
   })
   .sort((a, b) => {
     switch (filters.sort) {
      case 'priority':
        return b.priority - a.priority;
       case 'price-asc':
         return a.price - b.price;
       case 'price-desc':
         return b.price - a.price;
       case 'name-asc':
         return a.name.localeCompare(b.name);
       default:
         return 0;
     }
   });
   var lastFilteredProducts: any = Array();
   var i = 0;
   filteredProducts.forEach(element => {
      i += 1;
      if (filters.limit != 0) {
        if (i <= filters.limit) {
          lastFilteredProducts.push(element)
        }
      } else {
        lastFilteredProducts.push(element)
      }
   });

  return (
    <div className={styles.productsGrid}>
      {lastFilteredProducts.map((product:any) => (
        <div key={product.id} className={styles.productCard}>
          <div className={styles.productImageContainer}>
            <img 
              src={product.imageUrl}
              alt={product.name}
            />
            {product.priority >= 80 && (
              <div className={styles.popularBadge}>
                <StarIcon />
              </div>
            )}
          </div>
          <div className={styles.productInfo}>
            <h3 className={styles.productTitle}>{product.name}</h3>
            <p className={styles.productDescription}>{product.description}</p>
            <ul className={styles.featuresList}>
            {product.features.map((feature: any, index: any) => (
                <li key={index} className={styles.featureItem}>
                {feature}
                </li>
            ))}
            </ul>
            <div className={styles.productFooter}>
              <div className={styles.priceBlock}>
                <span className={styles.currency}>$</span>
                <span className={styles.amount}>{product.price.toLocaleString()}</span>
              </div>
              <button 
                className={styles.addToCartButton}
                onClick={() => addToCart(product)}
              >
                <ShoppingCartIcon />
                <span>В корзину</span>
              </button>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};


export default ProductList;