import React, { createContext, useState, useContext } from 'react';
import { Product } from '../types/types';
import ph1 from '../assets/images/cms-platform.svg';
import ph2 from '../assets/images/templates.svg';
import ph3 from '../assets/images/landing-builder.svg';
import ph4 from '../assets/images/ecommerce-platform.svg';
import ph5 from '../assets/images/wordpress-plugins.svg';
import ph6 from '../assets/images/website-dev.svg';
import ph7 from '../assets/images/crm-integration.svg';
import ph8 from '../assets/images/analytics.svg';
import ph9 from '../assets/images/microservices.svg';
import ph10 from '../assets/images/payment-integration.svg';
import ph11 from '../assets/images/telegram-bot.svg';
import ph12 from '../assets/images/chatbot.svg';
import ph13 from '../assets/images/marketing-automation.svg';
import ph14 from '../assets/images/business-automation.svg';
import ph15 from '../assets/images/social-bot.svg';
import ph16 from '../assets/images/database.svg';
import ph17 from '../assets/images/backup.svg';
import ph18 from '../assets/images/cloud-storage.svg';
import ph19 from '../assets/images/document-management.svg';
import ph20 from '../assets/images/data-migration.svg';


interface ProductContextType {
 products: Product[];
 addProduct: (product: Product) => void;
}

const ProductContext = createContext<ProductContextType>({
 products: [],
 addProduct: () => {},
});

export const useProductContext = () => useContext(ProductContext);

export const ProductProvider: React.FC<{children: React.ReactNode}> = ({ children }) => {
 const [products, setProducts] = useState<Product[]>([
   // Готовые решения для веб-разработки
   {
     id: 1,
     priority: 100,
     name: "Enterprise CMS Platform",
     description: "Профессиональная система управления контентом для крупных проектов с расширенными возможностями безопасности и масштабирования",
     price: 2999,
     category: "ready-solutions",
     imageUrl: ph1,
     features: [
       "Многопользовательский режим",
       "Расширенная система прав",
       "API для интеграции",
       "Встроенный CDN",
       "Защита от DDoS"
     ]
   },
   {
     id: 2,
     priority: 0,
     name: "Premium Website Templates Pack",
     description: "Коллекция профессиональных адаптивных шаблонов для различных типов бизнеса с исходными файлами и документацией",
     price: 799,
     category: "ready-solutions",
     imageUrl: ph2,
     features: [
       "50+ уникальных шаблонов",
       "Адаптивный дизайн",
       "SEO оптимизация",
       "Высокая производительность",
       "Регулярные обновления"
     ]
   },
   {
     id: 3,
     priority: 0,
     name: "Advanced Landing Page Builder",
     description: "Профессиональный конструктор лендингов с A/B тестированием и аналитикой",
     price: 1499,
     category: "ready-solutions",
     imageUrl: ph3,
     features: [
       "Drag-and-drop редактор",
       "A/B тестирование",
       "Интеграция с CRM",
       "Аналитика конверсий",
       "Оптимизация для мобильных"
     ]
   },
   {
     id: 4,
     priority: 100,
     name: "E-commerce Platform Pro",
     description: "Комплексное решение для создания и управления интернет-магазином корпоративного уровня",
     price: 3499,
     category: "ready-solutions",
     imageUrl: ph4,
     features: [
       "Мультивалютность",
       "Интеграция с маркетплейсами",
       "Система лояльности",
       "Автоматизация маркетинга",
       "Аналитика продаж"
     ]
   },
   {
     id: 5,
     priority: 0,
     name: "WordPress Enterprise Plugin Suite",
     description: "Набор премиум плагинов для WordPress с расширенной функциональностью",
     price: 899,
     category: "ready-solutions",
     imageUrl: ph5,
     features: [
       "SEO оптимизация",
       "Безопасность",
       "Кэширование",
       "Бэкап системы",
       "Интеграция с внешними сервисами"
     ]
   },

   // Разработка и интеграция
   {
     id: 6,
     priority: 0,
     name: "Enterprise Website Development",
     description: "Полный цикл разработки корпоративного сайта с уникальным дизайном и интеграциями",
     price: 15000,
     category: "development",
     imageUrl: ph6,
     features: [
       "Уникальный дизайн",
       "Комплексное тестирование",
       "Техническая поддержка",
       "SEO-оптимизация",
       "Обучение персонала"
     ]
   },
   {
     id: 7,
     priority: 100,
     name: "Full CRM Integration Package",
     description: "Комплексная интеграция CRM системы с телефонией и онлайн-кассой",
     price: 4999,
     category: "development",
     imageUrl: ph7,
     features: [
       "Настройка CRM",
       "Интеграция телефонии",
       "Подключение онлайн-кассы",
       "Автоматизация процессов",
       "Обучение сотрудников"
     ]
   },
   {
     id: 8,
     priority: 0,
     name: "Analytics Implementation Pro",
     description: "Профессиональная настройка систем аналитики и отслеживания",
     price: 2499,
     category: "development",
     imageUrl: ph8,
     features: [
       "Google Analytics 4",
       "Яндекс.Метрика",
       "Настройка целей",
       "E-commerce tracking",
       "Пользовательские отчеты"
     ]
   },
   {
     id: 9,
     priority: 0,
     name: "Microservices Architecture",
     description: "Разработка и внедрение микросервисной архитектуры",
     price: 12999,
     category: "development",
     imageUrl: ph9,
     features: [
       "Масштабируемая архитектура",
       "CI/CD pipeline",
       "Мониторинг",
       "Отказоустойчивость",
       "Документация API"
     ]
   },
   {
     id: 10,
     priority: 0,
     name: "Payment Systems Integration",
     description: "Комплексная интеграция платежных систем и эквайринга",
     price: 3999,
     category: "development",
     imageUrl: ph10,
     features: [
       "Множество платежных методов",
       "Безопасная обработка",
       "Автоматическая сверка",
       "Возвраты и чарджбэки",
       "Отчетность"
     ]
   },

   // Автоматизация
   {
     id: 11,
     priority: 0,
     name: "Enterprise Telegram Bot",
     description: "Разработка корпоративного Telegram бота с интеграцией внутренних систем",
     price: 2499,
     category: "automation",
     imageUrl: ph11,
     features: [
       "Интеграция с CRM",
       "Автоматические ответы",
       "Аналитика использования",
       "Панель управления",
       "API для расширения"
     ]
   },
   {
     id: 12,
     priority: 0,
     name: "Website Chat Bot Pro",
     description: "Интеллектуальный чат-бот для сайта с машинным обучением",
     price: 1899,
     category: "automation",
     imageUrl: ph12,
     features: [
       "ИИ для обработки запросов",
       "Мультиязычность",
       "Интеграция с CRM",
       "Аналитика диалогов",
       "Настраиваемые сценарии"
     ]
   },
   {
     id: 13,
     priority: 0,
     name: "Marketing Automation System",
     description: "Система автоматизации маркетинговых рассылок и коммуникаций",
     price: 3499,
     category: "automation",
     imageUrl: ph13,
     features: [
       "Email-маркетинг",
       "SMS-рассылки",
       "Push-уведомления",
       "A/B тестирование",
       "Сегментация аудитории"
     ]
   },
   {
     id: 14,
     priority: 0,
     name: "Business Process Automation",
     description: "Комплексная автоматизация бизнес-процессов компании",
     price: 7999,
     category: "automation",
     imageUrl: ph14,
     features: [
       "Workflow автоматизация",
       "Интеграция систем",
       "Отчетность",
       "Мониторинг",
       "Оптимизация процессов"
     ]
   },
   {
     id: 15,
     priority: 0,
     name: "Social Media Management Bot",
     description: "Автоматизированное управление социальными сетями",
     price: 1499,
     category: "automation",
     imageUrl: ph15,
     features: [
       "Публикация контента",
       "Модерация комментариев",
       "Аналитика активности",
       "Мониторинг упоминаний",
       "Автоответы"
     ]
   },

   // Системы хранения и управления данными
   {
     id: 16,
     priority: 0,
     name: "Enterprise Database Solution",
     description: "Комплексное решение для управления корпоративными данными",
     price: 9999,
     category: "data-systems",
     imageUrl: ph16,
     features: [
       "Высокая производительность",
       "Репликация",
       "Резервирование",
       "Безопасность",
       "24/7 поддержка"
     ]
   },
   {
     id: 17,
     priority: 0,
     name: "Backup System Pro",
     description: "Профессиональная система резервного копирования данных",
     price: 4999,
     category: "data-systems",
     imageUrl: ph17,
     features: [
       "Инкрементальное копирование",
       "Шифрование",
       "Версионность",
       "Автоматизация",
       "Мониторинг"
     ]
   },
   {
     id: 18,
     priority: 0,
     name: "Enterprise Cloud Storage",
     description: "Корпоративное облачное хранилище с расширенной безопасностью",
     price: 5999,
     category: "data-systems",
     imageUrl: ph18,
     features: [
       "Шифрование",
       "Контроль доступа",
       "Версионность",
       "Active Directory",
       "Аудит"
     ]
   },
   {
     id: 19,
     priority: 0,
     name: "Document Management System",
     description: "Система электронного документооборота корпоративного уровня",
     price: 6999,
     category: "data-systems",
     imageUrl: ph19,
     features: [
       "Электронный документооборот",
       "Workflow согласования",
       "Полнотекстовый поиск",
       "Интеграция с 1С",
       "ЭЦП"
     ]
   },
   {
     id: 20,
     priority: 0,
     name: "Data Migration Suite",
     description: "Инструменты для миграции и преобразования данных",
     price: 3999,
     category: "data-systems",
     imageUrl: ph20,
     features: [
       "Автоматическая миграция",
       "Валидация данных",
       "Мапинг полей",
       "Отчетность",
       "Поддержка форматов"
     ]
   }
 ]);

 const addProduct = (product: Product) => {
   setProducts([...products, { ...product, id: Date.now() }]);
 };

 return (
   <ProductContext.Provider value={{ products, addProduct }}>
     {children}
   </ProductContext.Provider>
 );
};

export default ProductProvider;