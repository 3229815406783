import React from 'react';
import styles from '../styles/electronic-store.module.css';
import SecurityIcon from '@mui/icons-material/Security';
import SpeedIcon from '@mui/icons-material/Speed';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import StorageIcon from '@mui/icons-material/Storage';
import CodeIcon from '@mui/icons-material/Code';
import AutoGraphIcon from '@mui/icons-material/AutoGraph';
import { Link } from 'react-router-dom';
import PaymentIcon from '@mui/icons-material/Payment';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import UpdateIcon from '@mui/icons-material/Update';
import IntegrationInstructionsIcon from '@mui/icons-material/IntegrationInstructions';


const benefits = [
  {
    icon: <SecurityIcon />,
    title: "Безопасность",
    description: "Гарантируем полную безопасность платежей и конфиденциальность данных"
  },
  {
    icon: <SpeedIcon />,
    title: "Быстрая доставка",
    description: "Мгновенный доступ к купленным цифровым продуктам"
  },
  {
    icon: <SupportAgentIcon />,
    title: "Поддержка 24/7",
    description: "Профессиональная техподдержка готова помочь в любое время"
  },
  {
    icon: <PaymentIcon />,
    title: "Удобная оплата",
    description: "Множество способов оплаты и прозрачное ценообразование"
  },
  {
    icon: <VerifiedUserIcon />,
    title: "Гарантия качества",
    description: "Тщательно проверяем все продукты перед публикацией"
  },
  {
    icon: <UpdateIcon />,
    title: "Обновления",
    description: "Регулярные обновления и поддержка всех продуктов"
  },
  {
    icon: <IntegrationInstructionsIcon />,
    title: "Простая интеграция",
    description: "Подробная документация и помощь в интеграции"
  }
 ];



export const HomePage: React.FC = () => {
  return (
    <div className={styles.homeContainer}>
      {/* Hero секция */}
      <section className={styles.heroSection}>
        <div className={styles.heroContent}>
          <h1>PixelTrek</h1>
          <p>Ваш надежный партнер в мире цифровых решений для бизнеса</p>
          <Link to="/catalog" className={styles.heroCatalogButton}>
            Перейти в каталог
          </Link>
        </div>
      </section>

      {/* Секция с основными направлениями */}
      <section className={styles.categoriesSection}>
        <h2>Основные направления</h2>
        <div className={styles.categoriesGrid}>
          <div className={styles.categoryCard}>
            <CodeIcon className={styles.categoryIcon} />
            <h3>Готовые решения</h3>
            <p>CMS системы, шаблоны и плагины для быстрого старта</p>
          </div>
          <div className={styles.categoryCard}>
            <StorageIcon className={styles.categoryIcon} />
            <h3>Разработка</h3>
            <p>Индивидуальная разработка и интеграция</p>
          </div>
          <div className={styles.categoryCard}>
            <AutoGraphIcon className={styles.categoryIcon} />
            <h3>Автоматизация</h3>
            <p>Оптимизация и автоматизация бизнес-процессов</p>
          </div>
        </div>
      </section>

      {/* Секция с преимуществами */}
      <section className={styles.benefitsSection}>
        <h2>Почему выбирают нас</h2>
        <div className={styles.benefitsScroll}>
          <div className={styles.benefitsTrack}>
            {/* Первый набор карточек */}
            {benefits.map((benefit, index) => (
              <div key={`benefit-1-${index}`} className={styles.benefitCard}>
                <div className={styles.benefitIcon}>{benefit.icon}</div>
                <h3>{benefit.title}</h3>
                <p>{benefit.description}</p>
              </div>
            ))}
            {/* Дублированный набор для бесконечной прокрутки */}
            {benefits.map((benefit, index) => (
              <div key={`benefit-2-${index}`} className={styles.benefitCard}>
                <div className={styles.benefitIcon}>{benefit.icon}</div>
                <h3>{benefit.title}</h3>
                <p>{benefit.description}</p>
              </div>
            ))}
          </div>
        </div>
      </section>



      {/* Секция со статистикой */}
      <section className={styles.statsSection}>
        <div className={styles.statsGrid}>
          <div className={styles.statCard}>
            <h3>50,000+</h3>
            <p>Довольных клиентов</p>
          </div>
          <div className={styles.statCard}>
            <h3>10,000+</h3>
            <p>Цифровых товаров</p>
          </div>
          <div className={styles.statCard}>
            <h3>99.9%</h3>
            <p>Успешных заказов</p>
          </div>
          <div className={styles.statCard}>
            <h3>24/7</h3>
            <p>Поддержка клиентов</p>
          </div>
        </div>
      </section>
    </div>
  );
};