import React from 'react';
import { useCartContext } from '../context/CartContext';
import { Link } from 'react-router-dom';
import styles from '../styles/electronic-store.module.css';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

export const CartPage: React.FC = () => {
  const { cart, removeFromCart, clearCart } = useCartContext();

  const totalPrice = cart.reduce((total, item) => 
    total + (item.price * item.quantity), 0
  );

  const handleCheckout = () => {
    cart.forEach(item => {
      if (item.digitalDownloadLink) {
        window.open(item.digitalDownloadLink, '_blank');
      }
    });
    clearCart();
  };

  if (cart.length === 0) {
    return (
      <div className={styles.emptyCart}>
        <ShoppingCartIcon className={styles.emptyCartIcon} />
        <h2>Корзина пуста</h2>
        <p>Добавьте товары для оформления заказа</p>
        <Link to="/catalog" className={styles.returnButton}>
          <ArrowBackIcon />
          Вернуться в каталог
        </Link>
      </div>
    );
  }

  return (
    <div className={styles.cartPageContainer}>
      <div className={styles.cartHeader}>
        <h1>Корзина</h1>
        <button 
          onClick={clearCart} 
          className={styles.clearCartButton}
        >
          Очистить корзину
        </button>
      </div>

      <div className={styles.cartContent}>
        <div className={styles.cartItems}>
          {cart.map(item => (
            <div key={item.id} className={styles.cartItem}>
              <div className={styles.cartItemImage}>
                <img src={item.imageUrl} alt={item.name} />
              </div>
              <div className={styles.cartItemInfo}>
                <h3>{item.name}</h3>
                <p className={styles.cartItemDescription}>{item.description}</p>
              </div>
              <div className={styles.cartItemQuantity}>
                <span>Количество: {item.quantity}</span>
              </div>
              <div className={styles.cartItemPrice}>
                <span>${(item.price * item.quantity).toLocaleString()}</span>
              </div>
              <button 
                onClick={() => removeFromCart(item.id)}
                className={styles.removeButton}
              >
                <DeleteOutlineIcon />
              </button>
            </div>
          ))}
        </div>

        <div className={styles.cartSummary}>
          <h2>Итого</h2>
          <div className={styles.summaryDetails}>
            <div className={styles.summaryRow}>
              <span>Товары ({cart.length}):</span>
              <span>${totalPrice.toLocaleString()}</span>
            </div>
          </div>
          <button 
            className={styles.checkoutButton}
            onClick={handleCheckout}
          >
            Оформить заказ
          </button>
          <Link to="/catalog" className={styles.continueShoppingButton}>
            <ArrowBackIcon />
            Продолжить покупки
          </Link>
        </div>
      </div>
    </div>
  );
};